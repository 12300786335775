export enum InventoryLocationType {
  FIXED = 'Fixed',
  JOB = 'Job',
  MOBILE = 'Mobile',
  GATEWAY = 'Gateway',
}

export interface InventoryLocation {
  id: number;
  invLocationId?: number;
  locationType: InventoryLocationType;
  name: string;
  lastReadDate?: Date;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  phoneName?: string;
  latitude?: number;
  longitude?: number;
  lastReadSecs?: number;
  gatewayType?: InventoryGatewayType;
  gatewayUuid?: string;
  fixedLocationId?: number;
  fixedLocationName?: string;
  isGpsCurrent?: boolean;
  status?: string;
}

export type InventoryLocations = InventoryLocation[];

export interface Coordinates {
  latitude: number;
  longitude: number;
  name: string;
}

export enum InventoryGatewayType {
  STAND_ALONE = 'Stand-alone',
  VEHICLE = 'Vehicle',
  WAREHOUSE = 'Warehouse',
}

// Global custom location id.
let inventoryLocationID = 1;

export const nextInventoryLocationID = () => {
  const id = inventoryLocationID;
  inventoryLocationID += 1;
  return id;
};

export const getNewInventoryLocation = (locationType: InventoryLocationType) =>
  ({
    id: -nextInventoryLocationID(),
    invLocationId: undefined,
    locationType,
    name: '',
    address: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    phoneName: undefined,
    latitude: undefined,
    longitude: undefined,
    lastReadSecs: undefined,
    gatewayType: InventoryGatewayType.STAND_ALONE,
    gatewayUuid: undefined,
    fixedLocationId: undefined,
    fixedLocationName: '',
    isGpsCurrent: false,
    status: '',
  }) as InventoryLocation;
