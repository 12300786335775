import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;

import InfoDialog from '@/components/dialogs/InfoDialog.vue';
import { getErrorMessage } from '@/utils/error';

export default (errorProp) => ({
  data() {
    return {
      quasar: useQuasar(),
    };
  },
  computed: {
    errorMessage() {
      // Error may be enhanced by axios.
      const error = this[errorProp];
      if (!error) {
        return undefined;
      }
      return getErrorMessage(error);
    },
  },
  watch: {
    errorMessage(message) {
      const { quasar } = this as any;
      if (quasar && message) {
        quasar.dialog({
          component: InfoDialog,
          componentProps: {
            headerText: 'Error',
            description: message,
          },
        });
        setTimeout(() => {
          this[errorProp] = undefined;
        }, 0);
      }
    },
  },
});
