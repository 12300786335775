import { acceptHMRUpdate, defineStore } from 'pinia';

import type { MenuData } from '@/models/menu-data.type';

interface MenuState {
  data: MenuData | undefined;
}

const useMenuStore = defineStore('menu', {
  state: (): MenuState => ({
    data: undefined,
  }),
  getters: {
    getData(state: MenuState) {
      return state.data;
    },
  },
  actions: {
    setData(data: MenuData) {
      this.data = data;
    },
  },
});

export default useMenuStore;

if (module.hot) {
  module.hot.accept(acceptHMRUpdate(useMenuStore, module.hot));
}
