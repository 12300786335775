import { acceptHMRUpdate, defineStore } from 'pinia';

interface AuthState {
  isPasswordReset: boolean;
  keepSignedIn: boolean;
  shouldConfirmLicense: boolean | undefined;
  token: any;
  triedRefreshing: boolean;
}
const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    isPasswordReset: false,
    keepSignedIn: false,
    shouldConfirmLicense: false as boolean | undefined,
    token: undefined as any,
    triedRefreshing: false,
  }),
  actions: {
    setResetPassword(payload: boolean) {
      this.isPasswordReset = payload;
    },
    setKeepSignedIn(payload: boolean) {
      this.keepSignedIn = payload;
      window.localStorage.setItem('keepSignedIn', JSON.stringify(payload));
    },
    getKeepSignedIn() {
      const value = window.localStorage.getItem('keepSignedIn');
      if (value == null) {
        return undefined;
      }
      this.keepSignedIn = JSON.parse(value);
      return this.keepSignedIn;
    },
    setTriedRefreshing(payload: boolean) {
      this.triedRefreshing = payload;
    },
    getTriedRefreshing() {
      return this.triedRefreshing;
    },
    setShouldConfirmLicense(payload: boolean | undefined) {
      this.shouldConfirmLicense = payload;
      window.localStorage.setItem('shouldConfirmLicense', JSON.stringify(payload ?? null));
    },
    getShouldConfirmLicense() {
      const value = window.localStorage.getItem('shouldConfirmLicense');
      if (value == null) {
        return undefined;
      }
      this.shouldConfirmLicense = JSON.parse(value);
      return this.shouldConfirmLicense;
    },
    setCredentials(payload) {
      window.localStorage.setItem('credentials', JSON.stringify(payload ?? null));
    },
    getCredentials() {
      const value = window.localStorage.getItem('credentials');
      if (value == null) {
        return undefined;
      }
      return JSON.parse(value);
    },
    removeCredentials() {
      window.localStorage.removeItem('credentials');
    },
    getToken() {
      if (!this.token) {
        const value = window.localStorage.getItem('token');
        if (value == null) {
          return undefined;
        }
        this.token = JSON.parse(value);
      }
      return this.token;
    },
    setToken(token: any) {
      this.token = token;
      window.localStorage.setItem('token', JSON.stringify(token ?? null));
    },
    deleteToken() {
      this.token = undefined;
      window.localStorage.removeItem('token');
    },
  },
});
export default useAuthStore;

if (module.hot) {
  module.hot.accept(acceptHMRUpdate(useAuthStore, module.hot));
}
