export const ROUTE_PARTS = {
  LOGIN: '',
  EQUIPMENT: 'equipment',
  JOBS: 'jobs',
  CHAMBERS: 'chambers',
  ROOMS: 'rooms',
  ASSETS: 'assets',
  VEHICLES: 'vehicles',
  MULTI_COMPANY_ASSETS: 'multi-company-assets',
  VISITS: 'visits',
  SIGNUP: 'signup',
  SETTINGS: 'settings',
  LOCATION: 'location',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  ACCOUNT: 'account',
  SYNCED_EQUIPMENT: 'synced-equipment',
  CLAIMS: 'claims',
  RELOAD_PAGE: 'reload-page',
};

export const getRoute = (...parts: (number | string)[]) => `/${parts.join('/')}`;
