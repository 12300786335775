import { acceptHMRUpdate, defineStore } from 'pinia';

interface SettingTestAccountState {
  isTestAccount: boolean;
}

const useSettingTestAccountStore = defineStore('settingTestAccount', {
  state: (): SettingTestAccountState => ({
    isTestAccount: true,
  }),

  actions: {
    setTestAccount(isTestAccount: boolean) {
      this.isTestAccount = isTestAccount;
    },
  },
});

export default useSettingTestAccountStore;

if (module.hot) {
  module.hot.accept(acceptHMRUpdate(useSettingTestAccountStore, module.hot));
}
