import '@quasar/extras/material-icons/material-icons.css';
import './styles/quasar.scss';

import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import Notify from 'quasar/src/plugins/notify/Notify.js';;

// To be used on app.use(Quasar, { ... }).
export default {
  config: {},
  components: {},
  plugins: {
    Dialog,
    Loading,
    Notify,
  },
};
