/* eslint-disable no-param-reassign */
import type { AxiosError, AxiosInstance } from 'axios';

import { getRoute, ROUTE_PARTS } from '@/router/constants';
import { login } from '@/services/login';
import useAuthStore from '@/store/auth';

export const refreshToken = async (store, http) => {
  // console.log('refresh', store.getToken().refreshtoken);
  try {
    const authData = await login(http, {
      refreshToken: store.getToken().refreshtoken,
    });
    store.setToken(authData);
  } catch {
    store.deleteToken();
    store.setTriedRefreshing(true);
  }
};

export const errorInterceptor = async (
  error: AxiosError,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  router,
  http: AxiosInstance,
): Promise<AxiosError> => {
  console.error(error);

  if (error.response?.status === 401) {
    const authStore = useAuthStore();

    if (!authStore.getTriedRefreshing() && authStore.getToken() && authStore.keepSignedIn && error.config != null) {
      await refreshToken(authStore, http);

      error.config.headers['X-Auth-Token'] = authStore.getToken().accesstoken;
      error.config.baseURL = undefined;
      return http.request(error.config);
    }

    authStore.deleteToken();
    const loginRoute = getRoute(ROUTE_PARTS.LOGIN);
    if (router.currentRoute.value.path !== loginRoute) {
      router.push(loginRoute);
    }
  }

  return Promise.reject(error);
};
