import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheHeader),
    _createElementVNode("div", {
      class: _normalizeClass(["container", _ctx.containerClass])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createVNode(_component_TheFooter, { class: "footer" })
  ], 64))
}