<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin dialog">
      <DialogHeader :header-text="headerText" @close-clicked="onDialogHide" />
      <div v-html="description"></div>

      <div class="dialog-actions">
        <div>
          <button :class="confirmBtnClass" type="button" :disabled="isConfirmBtnDisabled" @click="onClickConfirmAction">
            {{ btnConfirmText }}
          </button>
          <BaseTooltip v-if="confirmBtnTooltip">{{ confirmBtnTooltip }}</BaseTooltip>
        </div>

        <button v-if="hasCancel" type="button" class="btn-dialog-cancel btn-dialog" @click="onClickCancelAction">
          {{ btnCancelText }}
        </button>
      </div>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { useDialogPluginComponent } from 'quasar';
import { defineComponent } from 'vue';

import BaseTooltip from '@/components/base/BaseTooltip.vue';
import DialogHeader from '@/components/dialogs/DialogHeader.vue';

export default defineComponent({
  name: 'InfoDialog',
  props: {
    headerText: String,
    description: String,
    btnConfirmText: {
      type: String,
      default: 'OK',
    },
    hasCancel: Boolean,
    btnCancelText: {
      type: String,
      default: 'Cancel',
    },
    confirmBtnClass: {
      type: String,
      default: 'btn-default',
    },
    isConfirmBtnDisabled: {
      type: Boolean,
      default: false,
    },
    confirmBtnTooltip: {
      type: String,
      default: '',
    },
    onClickConfirm: Function,
    onClickCancel: Function,
  },
  components: {
    BaseTooltip,
    DialogHeader,
  },
  emits: [...useDialogPluginComponent.emits],
  setup() {
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent();

    return {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    };
  },
  methods: {
    onClickConfirmAction() {
      if (this.onClickConfirm) {
        this.onClickConfirm();
      }
      this.onDialogOK();
    },
    onClickCancelAction() {
      if (this.onClickCancel) {
        this.onClickCancel();
      }
      this.onDialogCancel();
    },
  },
});
</script>
