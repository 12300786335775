<template>
  <TheHeader />
  <div class="container" :class="containerClass">
    <slot />
  </div>
  <TheFooter class="footer" />
</template>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: var(--container-width);
  min-height: 320px;
  padding: 16px 24px 32px;
}
.footer {
  display: flex;
  align-items: center;
  justify-self: center;
  min-height: 70px;
}
</style>

<script lang="ts">
import { Loading } from 'quasar';
import { defineComponent, onMounted, ref } from 'vue';

import settingsService from '@/services/settings.service';
import subscriptionService from '@/services/subscription.service';
import useSettingTestAccountStore from '@/store/settings/test-account';
import useSubscriptionStore from '@/store/subscription';

import TheFooter from './TheFooter.vue';
import TheHeader from './TheHeader.vue';

export default defineComponent({
  name: 'AppLayoutDefault',
  components: {
    TheHeader,
    TheFooter,
  },
  props: {
    containerClass: String,
  },
  setup() {
    const error = ref<Error>();
    const subscriptionStore = useSubscriptionStore();
    const testAccountStore = useSettingTestAccountStore();

    const getAssetsEnabled = async () => {
      const loading = Loading.show({ group: 'getAssetsEnabled' });
      error.value = undefined;
      try {
        const assets = await subscriptionService.getSubscriptionForAssets();
        subscriptionStore.setAssets(assets);
      } catch (e: any) {
        error.value = e;
      } finally {
        loading();
      }
    };

    const getJobsEnabled = async () => {
      const loading = Loading.show({ group: 'getJobsEnabled' });
      error.value = undefined;
      try {
        const jobs = await subscriptionService.getSubscriptionForJobs();
        subscriptionStore.setJobs(jobs);
      } catch (e: any) {
        error.value = e;
      } finally {
        loading();
      }
    };

    const getTestAccountData = async () => {
      if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
        // Getting test account data only on staging.
        const isTestAccount = await settingsService.getIsTestAccount();
        testAccountStore.setTestAccount(isTestAccount);
      }
    };

    onMounted(async () => {
      await getAssetsEnabled();
      await getJobsEnabled();
      await getTestAccountData();
    });

    return { error };
  },
});
</script>
