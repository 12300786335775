<template>
  <q-tooltip
    class="help-tooltip base-tooltip"
    ref="baseTooltip"
    anchor="top start"
    self="bottom start"
    :offset="[0, 6]"
    @before-show="beforeShow"
  >
    <span class="base-tooltip__pointer" :style="{ left }"></span>
    <slot />
  </q-tooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseTooltip',
  props: {
    getAttachedElement: Function,
  },
  data() {
    return {
      left: undefined as string | undefined,
    };
  },
  methods: {
    beforeShow() {
      if (this.getAttachedElement) {
        const element = this.getAttachedElement() as HTMLElement | undefined;
        if (element) {
          const width = element.offsetWidth;
          this.left = `${width / 2}px`;
        } else {
          this.left = undefined;
        }
      } else {
        this.left = undefined;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.base-tooltip {
  position: relative;
  padding: 5px 8px;
  font-size: 12px;
  line-height: 14px;
  box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));

  &__pointer {
    position: absolute;
    left: calc(50% - 6px);
    top: 100%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--dark-gray);
  }
}

:global(.base-tooltip.q-tooltip) {
  overflow: visible;
}
</style>
