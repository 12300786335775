import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(_ctx.svgClass),
    "aria-hidden": "true",
    width: _ctx.width || _ctx.size,
    height: _ctx.height || _ctx.size
  }, [
    _createElementVNode("use", { "xlink:href": _ctx.name }, null, 8, _hoisted_2)
  ], 10, _hoisted_1))
}