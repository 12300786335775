<template>
  <div class="wrapper">
    <!-- Using 'div' as default to avoid excess request for header data on fallback with AppDefaultLayout -->
    <component :is="$route.meta.layout || 'div'">
      <router-view></router-view>
    </component>
  </div>
  <div class="container-top">
    <a href="#" class="back-to-top flex-center">
      <SvgIcon class="icon" icon-name="arrow-right" width="8" height="14" />
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

import SvgIcon from '@/components/base/SvgIcon.vue';
import AppLayoutAuth from '@/layouts/AppLayoutAuth.vue';
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue';
import CustomLayoutWithOverflow from '@/layouts/CustomLayoutWithOverflow.vue';
import SignUp from '@/layouts/SignUp.vue';

export default defineComponent({
  name: 'App',
  components: {
    AppLayoutDefault,
    AppLayoutAuth,
    CustomLayoutWithOverflow,
    SignUp,
    SvgIcon,
  },
  setup() {
    const version = ref<string>();

    onMounted(() => {
      version.value = process.env.VUE_APP_VERSION;
    });

    return { version };
  },
});
</script>

<style lang="scss" scoped>
.back-to-top {
  position: sticky;
  bottom: 16px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: var(--gray);
  color: var(--light-gray);

  .icon {
    transform: rotate(-90deg);
  }
}

.container-top {
  --offset: 100px;
  --fade: 120px;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 40px;
  justify-self: end;
  justify-content: flex-start;
  align-items: flex-end;
  mask: linear-gradient(#0000 calc(100vh + var(--offset)), #000 calc(100vh + var(--offset) + var(--fade)));
  z-index: 3;
}
</style>
