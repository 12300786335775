import type { AxiosRequestConfig } from 'axios';

import useAuthStore from '@/store/auth';

export const authInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const authStore = useAuthStore();
  const token = authStore.getToken()?.accesstoken;
  if (config.headers == undefined) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {};
  }

  if (token != null) {
    // eslint-disable-next-line no-param-reassign
    config.headers['X-Auth-Token'] = token;
  }

  return config;
};
