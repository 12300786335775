export const getNumberFromStorage = (storage, key) => {
  let value = parseInt(storage.getItem(key), 10);
  if (Number.isNaN(value)) {
    value = 0;
  }
  return value;
};

export const setNumberToStorage = (storage, key, value) => {
  storage.setItem(key, `${value}`);
};
export const clearStorageGroup = (startsWith: string) => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith(startsWith)) {
      localStorage.removeItem(key);
    }
  });
};
export const clearTabLocalStorage = () => {
  clearStorageGroup('tabsync:');
};

export const clearVehicleLocalStorage = () => {
  clearStorageGroup('vehicles:');
};
export const loadTab = () => {
  let tabsCount = getNumberFromStorage(localStorage, 'tabsCount');
  const tabId = getNumberFromStorage(sessionStorage, 'tabId');
  if (tabId === 0 && tabsCount === 0) {
    // Previous tab was the last one and it has been closed (not page refresh).
    clearTabLocalStorage();
  }
  tabsCount += 1;
  setNumberToStorage(sessionStorage, 'tabId', tabsCount);
  setNumberToStorage(localStorage, 'tabsCount', tabsCount);
};

export const unloadTab = () => {
  const tabsCount = getNumberFromStorage(localStorage, 'tabsCount');
  if (tabsCount > 0) {
    setNumberToStorage(localStorage, 'tabsCount', tabsCount - 1);
  } else {
    localStorage.removeItem('tabsCount');
  }
};

export default function prepareLocalStorage() {
  loadTab();
  window.addEventListener('beforeunload', unloadTab);
}
