<template>
  <div class="settings-link-block">
    <!-- TODO: create constanst with route names -->
    <router-link class="link settings-link" :to="{ name: 'Settings', params: { tab: 'my-settings' } }">
      <img src="../assets/images/background/settings.svg" class="setting-icon" />
      Settings
    </router-link>
    <router-link to="/account" class="settings-link">
      <div v-if="initials" class="avatar">{{ initials }}</div>
      <span class="link">{{ fullName }}</span>
    </router-link>
    <a href="##" class="link settings-link" @click.prevent="logout">Sign Out</a>
  </div>
</template>

<style lang="scss" scoped>
.settings-link-block {
  display: flex;
  align-items: center;
  gap: 26px;

  .settings-link {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;

    &:not(:last-child):after {
      content: '';
      position: absolute;
      right: -13px;
      width: 1px;
      height: 15px;
      background-color: var(--placeholder);
    }
  }
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--placeholder);
  font-weight: bold;
  text-decoration: none;
}
</style>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import { getRoute, ROUTE_PARTS } from '@/router/constants';
import useAuthStore from '@/store/auth';

export default defineComponent({
  name: 'TheSettingsLinks',
  props: {
    firstName: String,
    lastName: String,
  },
  setup(props) {
    const $router = useRouter();
    const authStore = useAuthStore();

    const fullName = computed((): string =>
      props.firstName ?? props.lastName ? `${props.firstName} ${props.lastName}` : 'Profile',
    );
    const initials = computed((): string => {
      const { firstName, lastName } = props;
      if (!firstName || !lastName) {
        return '';
      }
      return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    });

    const logout = async () => {
      authStore.deleteToken();
      authStore.setShouldConfirmLicense(undefined);
      await $router.push(getRoute(ROUTE_PARTS.LOGIN));
    };

    return {
      fullName,
      logout,
      initials,
    };
  },
});
</script>
