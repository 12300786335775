import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f5bf7f44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "container-top" }
const _hoisted_3 = {
  href: "#",
  class: "back-to-top flex-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SvgIcon = _resolveComponent("SvgIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$route.meta.layout || 'div'), null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        _createVNode(_component_SvgIcon, {
          class: "icon",
          "icon-name": "arrow-right",
          width: "8",
          height: "14"
        })
      ])
    ])
  ], 64))
}