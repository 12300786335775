<template>
  <header class="header">
    <div class="nav">
      <router-link to="/jobs">
        <img class="logo" alt="Logo" src="@/assets/images/logo.svg" />
      </router-link>
      <TheNav :job-count="data?.jobCount" :equip-count="data?.equipCount" />
    </div>
    <div class="settings">
      <TheSettingsLinks :first-name="data?.firstName" :last-name="data?.lastName" />
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--container-width);
  margin-bottom: 16px;
  margin-top: 10px;
}
.logo {
  height: auto;
  margin-right: 40px;
}
.nav {
  display: flex;
  align-items: center;
}
</style>

<script lang="ts">
import { Loading } from 'quasar';
import { computed, defineComponent, onMounted, ref } from 'vue';

import ErrorDialogMixin from '@/components/dialogs/ErrorDialogMixin';
import authService from '@/services/auth.service';
import useMenuStore from '@/store/menu';
import eventBus from '@/utils/event-bus';

import TheNav from './TheNav.vue';
import TheSettingsLinks from './TheSettingsLinks.vue';

export default defineComponent({
  name: 'TheHeader',
  mixins: [ErrorDialogMixin('error')],
  components: {
    TheNav,
    TheSettingsLinks,
  },
  setup() {
    const error = ref<Error>();
    const menuStore = useMenuStore();

    const data = computed(() => menuStore.getData);

    const fetchMenuData = async () => {
      const loading = Loading.show({ group: 'fetchMenuData' });
      try {
        const { data: menuData } = await authService.getMenuData();
        menuStore.setData(menuData);
      } catch (e: any) {
        error.value = e;
      } finally {
        loading();
      }
    };

    onMounted(async () => {
      await fetchMenuData();
      eventBus.on('fetchMenuData', fetchMenuData.bind(this));
    });

    return { data };
  },
});
</script>
