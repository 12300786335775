const events = ['click'];

export const onClickOutside = ({ event, el, handler, middleware }) => {
  const isClickOutside = event.target !== el && !el.contains(event.target);

  if (!isClickOutside || !middleware(event, el)) {
    return undefined;
  }

  return handler(event, el);
};

const instances = new Map();

// Requires loop to toggle events for several listeners of an element.
export const toggleEventListeners = (eventHandlers) => (action) => {
  eventHandlers.forEach(({ event, handler }) => {
    document[`${action}EventListener`](event, handler, true);
  });
};

// Validator function.
export const processArgs = (value) => {
  const isFunction = typeof value === 'function';

  if (!isFunction && typeof value !== 'object') {
    throw new Error('v-click-outside: Binding value should be a function or an object, typeof bindingValue given');
  }

  return {
    handler: isFunction ? value : value.handler,
    middleware: value.middleware || (() => true),
  };
};

// Now need adapter to handle several events for one Map element.
export const eventAdapter = (eventList, { el, handler, middleware }) =>
  eventList.map((eventName) => ({
    event: eventName,
    handler: (event) => onClickOutside({ event, el, handler, middleware }),
  }));

export const bind = (el, { value }) => {
  const { handler, middleware } = processArgs(value);
  const eventHandlers = eventAdapter(events, { el, handler, middleware });

  instances.set(el, eventHandlers);

  toggleEventListeners(eventHandlers)('add');
};

export const unbind = (el) => {
  const eventHandlers = instances.get(el);

  toggleEventListeners(eventHandlers)('remove');

  instances.delete(el);
};

const clickOutside = {
  beforeMount: bind,
  unmounted: unbind,
};

export default clickOutside;
