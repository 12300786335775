import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dialog-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.headerText), 1),
    _createVNode(_component_SvgIcon, {
      "icon-name": "close",
      class: "close-btn",
      size: "14",
      onClick: _ctx.onCloseIconClick
    }, null, 8, ["onClick"])
  ]))
}