<template>
  <nav>
    <router-link class="nav-link" :class="{ 'nav-link--active': $route.path.startsWith(JOBS_LINK) }" :to="JOBS_LINK">
      Jobs ({{ jobCount }})
    </router-link>
    <router-link
      class="nav-link"
      :class="{ 'nav-link--active': $route.path.startsWith(EQUIPMENT_LINK) }"
      :to="EQUIPMENT_LINK"
    >
      Equipment ({{ equipCount }})
    </router-link>

    <q-btn
      v-if="isAssetsEnabled"
      class="nav-link nav-submenu"
      :class="{
        'nav-link--enabled': isSubNavActive('asset'),
        'nav-link--active': $route.path.startsWith(ASSETS_LINK) || $route.path.startsWith(VEHICLES_LINK),
      }"
      label="ASSET MANAGER"
      icon-right="keyboard_arrow_up"
    >
      <q-menu
        class="submenu-menu"
        @before-show="enableSubNav('asset')"
        @before-hide="disableSubNav('asset')"
        auto-close
        fit
      >
        <q-list>
          <q-item clickable>
            <q-item-section>
              <router-link class="nav-link" :to="ASSETS_LINK">Tagged Assets</router-link>
            </q-item-section>
          </q-item>
          <q-item clickable>
            <q-item-section>
              <router-link class="nav-link" :to="VEHICLES_LINK">Vehicle History</router-link>
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
    <router-link
      v-else
      class="nav-link"
      :class="{ 'nav-link--active': $route.fullPath === ASSETS_LINK }"
      :to="ASSETS_LINK"
    >
      Asset Manager
    </router-link>

    <q-btn
      class="nav-link nav-submenu"
      :class="{
        'nav-link--enabled': isSubNavActive(MULTI_COMPANY_ASSETS_LINK),
        'nav-link--active': $route.path.startsWith(MULTI_COMPANY_ASSETS_LINK),
      }"
      label="ENTERPRISE"
      icon-right="keyboard_arrow_up"
    >
      <q-menu
        class="submenu-menu"
        @before-show="enableSubNav(MULTI_COMPANY_ASSETS_LINK)"
        @before-hide="disableSubNav(MULTI_COMPANY_ASSETS_LINK)"
        auto-close
        fit
      >
        <q-list>
          <q-item clickable>
            <q-item-section>
              <router-link class="nav-link" :to="MULTI_COMPANY_ASSETS_LINK">Available</router-link>
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </nav>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia';
import { defineComponent, ref } from 'vue';

import { getRoute, ROUTE_PARTS } from '@/router/constants';
import useSubscriptionStore from '@/store/subscription';

export const JOBS_LINK = getRoute(ROUTE_PARTS.JOBS);
export const EQUIPMENT_LINK = getRoute(ROUTE_PARTS.EQUIPMENT);
export const ASSETS_LINK = getRoute(ROUTE_PARTS.ASSETS);
export const VEHICLES_LINK = getRoute(ROUTE_PARTS.VEHICLES);
export const MULTI_COMPANY_ASSETS_LINK = getRoute(ROUTE_PARTS.MULTI_COMPANY_ASSETS);

export default defineComponent({
  name: 'TheNav',
  props: {
    jobCount: Number,
    equipCount: Number,
  },
  setup() {
    const subNavActiveMap = ref(new Map<string, boolean>());
    const subscriptionStore = useSubscriptionStore();
    const { isAssetsEnabled } = storeToRefs(subscriptionStore);

    const isSubNavActive = (name: string) => subNavActiveMap.value.get(name);
    const enableSubNav = (name: string) => {
      subNavActiveMap.value.set(name, true);
    };
    const disableSubNav = (name: string) => {
      subNavActiveMap.value.set(name, false);
    };

    return {
      isSubNavActive,
      enableSubNav,
      disableSubNav,
      isAssetsEnabled,
      JOBS_LINK,
      EQUIPMENT_LINK,
      ASSETS_LINK,
      VEHICLES_LINK,
      MULTI_COMPANY_ASSETS_LINK,
    };
  },
});
</script>

<style lang="scss" scoped>
.nav-link {
  padding: 6px 24px;
  border-radius: 4px;
  line-height: 24px;
  font-family: var(--base-font);
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  &--active {
    background-color: var(--red);
  }
}

.nav-submenu {
  display: inline;
  padding: 4px 16px 4px 24px;
  min-height: 0;
  vertical-align: inherit;

  &:before {
    // Blocking q-btn actions classes.
    box-shadow: none !important;
  }
}

// Styles for icon in main button.
:global(.nav-submenu .q-icon) {
  margin-left: 3px;
  transition-property: rotate;
  transition-duration: var(--transition-duration);
}

// Enabled style for nav link.
:global(.nav-submenu.nav-link--enabled) {
  background-color: var(--button-gray);
}

// Active style for nav link.
:global(.nav-submenu.nav-link--active) {
  background-color: var(--red);
}

// Rotating icon for enabled nav link.
:global(.nav-submenu.nav-link--enabled .q-icon) {
  rotate: -180deg;
}

:global(.submenu-menu.q-position-engine) {
  // Offset between button and submenu.
  margin-top: 2px !important;

  // Submenu has min width, we are going to use it as width of links list.
  width: min-content;
}

// Removing background color from submenu.
:global(.submenu-menu.q-menu) {
  background-color: initial;
}

// Resetting styles for links in submenu.
:global(.submenu-menu .q-item.q-link) {
  min-height: 0;
  padding: 0;
}

// Styles for link in submenu.
:global(.submenu-menu .q-item.q-link .nav-link) {
  padding: 6px 24px;
  line-height: 24px;
  color: var(--white);
  background-color: var(--button-gray);
}

// Styles for focused link in submenu.
:global(.submenu-menu .q-item.q-link .nav-link:hover) {
  background-color: var(--button-hover-gray);
}

// Styles for active link in submenu.
:global(.submenu-menu .q-item.q-link .nav-link.router-link-active) {
  background-color: var(--red);
}
</style>
