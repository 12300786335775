// TODO: maybe change name to subsription after discussion with Diane.
export interface TierPricing {
  featureName: string;
  freetrialDurationDays: number;
  numGateways: number;
  pricePerMonth: number;
  pricePerUnit: number;
  priceLabel: string;
  tierDescription: string;
  tierName: string;
}

export enum SubscriptionStatus {
  ENABLED = 0,
  TRIAL = 1,
  INACTIVE = 2,
}

interface Subscription {
  endFreetrialSecs: number;
  freetrialDurationDays: number;
  display_name: string;
  featureName: string;
  featureDescription: string[] | string;
  numberOfGateways: number;
  message: string;
  status: SubscriptionStatus;
  tierPricing: TierPricing[];
}
export default Subscription;
