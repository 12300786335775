import { PromisePool } from '@supercharge/promise-pool';

export type MapCallback<K, V> = (item: K, index: number) => Promise<V> | V;

export const mapInSequential = async <K, V>(items: K[], callback: MapCallback<K, V>) =>
  items.reduce<Promise<V[]>>(
    async (promise, item, index) =>
      promise.then(async (values) => {
        const value = await callback(item, index);
        values.push(value);
        return values;
      }),
    Promise.resolve([]),
  );

export const filterInSequential = async <K, V>(items: K[], callback: MapCallback<K, V>) =>
  items.reduce<Promise<V[]>>(
    async (promise, item, index) =>
      promise.then(async (values) => {
        try {
          const value = await callback(item, index);
          values.push(value);
          return values;
        } catch (_error) {
          return values;
        }
      }),
    Promise.resolve([]),
  );

export const someInSequential = async <K, V>(items: K[], callback: MapCallback<K, V>) => {
  for (let index = 0; index < items.length; index += 1) {
    try {
      const item = items[index];
      const result = await callback(item, index);
      return result;
    } catch (_error) {
      // Ok.
    }
  }

  return undefined;
};

export const mapInParallel = async <K, V>(items: K[], callback: MapCallback<K, V>) => {
  const promises = items.map(callback);
  const results = await Promise.allSettled(promises);

  return results.map((result) => {
    if (result.status === 'fulfilled') {
      return result.value;
    }
    throw result.reason;
  });
};

export const filterInParallel = async <K, V>(items: K[], callback: MapCallback<K, V>) => {
  const promises = items.map(callback);
  const results = await Promise.allSettled(promises);

  return results.reduce<V[]>((values, result) => {
    if (result.status === 'fulfilled') {
      values.push(result.value);
    }
    return values;
  }, []);
};

export const mapInPool = async <K, V>(items: K[], poolSize: number, callback: MapCallback<K, V>) => {
  if (!poolSize) {
    return [];
  }

  const { results } = await PromisePool.withConcurrency(poolSize)
    .for(items)
    .handleError((error) => {
      throw error;
    })
    .process(callback);

  return results;
};

export const filterInPool = async <K, V>(items: K[], poolSize: number, callback: MapCallback<K, V>) => {
  if (!poolSize) {
    return [];
  }

  const { results } = await PromisePool.withConcurrency(poolSize)
    .for(items)
    .handleError(() => {
      /* Ok */
    })
    .process(callback);

  return results;
};
