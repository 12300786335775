import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "dialog-actions" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogHeader = _resolveComponent("DialogHeader")!
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref: "dialogRef",
    onHide: _ctx.onDialogHide
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "q-dialog-plugin dialog" }, {
        default: _withCtx(() => [
          _createVNode(_component_DialogHeader, {
            "header-text": _ctx.headerText,
            onCloseClicked: _ctx.onDialogHide
          }, null, 8, ["header-text", "onCloseClicked"]),
          _createElementVNode("div", { innerHTML: _ctx.description }, null, 8, _hoisted_1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.confirmBtnClass),
                type: "button",
                disabled: _ctx.isConfirmBtnDisabled,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickConfirmAction && _ctx.onClickConfirmAction(...args)))
              }, _toDisplayString(_ctx.btnConfirmText), 11, _hoisted_3),
              (_ctx.confirmBtnTooltip)
                ? (_openBlock(), _createBlock(_component_BaseTooltip, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.confirmBtnTooltip), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            (_ctx.hasCancel)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn-dialog-cancel btn-dialog",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickCancelAction && _ctx.onClickCancelAction(...args)))
                }, _toDisplayString(_ctx.btnCancelText), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide"]))
}