export const restrictInput = (el, modifiers) => {
  const numberRegex = /[^0-9]/giu;
  const negativeRegex = /[^0-9-]/giu;
  const floatRegex = /[^0-9.]/giu;
  const negativeFloatRegex = /[^0-9.-]/giu;
  let toUse = numberRegex;
  if (modifiers.float) toUse = floatRegex;
  if (modifiers.negative) toUse = negativeRegex;
  if (modifiers.float && modifiers.negative) toUse = negativeFloatRegex;
  // eslint-disable-next-line no-param-reassign
  el.target.value = el.target.value.replace(toUse, '');
};

export const bind = (el, { modifiers }) => {
  el.addEventListener('input', (event) => {
    restrictInput(event, modifiers);
  });
};

export const unbind = (el) => {
  el.removeEventListener('input', restrictInput);
};

const onlyNumbers = {
  beforeMount: bind,
  unmounted: unbind,
};

export default onlyNumbers;
