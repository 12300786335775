<template>
  <div class="logo">
    <img alt="Logo" src="@/assets/images/logo.svg" />
  </div>
  <div class="container">
    <slot />
  </div>
  <TheFooter class="footer" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import TheFooter from './TheFooter.vue';

export default defineComponent({
  name: 'TheSignUp',
  components: {
    TheFooter,
  },
});
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 24px 56px 32px 56px;
}
.logo {
  margin-top: 56px;
  margin-bottom: 40px;

  img {
    width: 138px;
  }
}
.footer {
  margin-top: 79px;
}
</style>
