import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';

import { authInterceptor } from '@/interceptors/auth.interceptor';
import { errorInterceptor } from '@/interceptors/error.interceptor';
import { type MapCallback, mapInPool } from '@/utils/promise';

export const MAX_SIMULTANEOUS_QUERIES = 8;

export const mapQueriesInParallel = async <K, V>(items: K[], callback: MapCallback<K, V>) =>
  mapInPool(items, MAX_SIMULTANEOUS_QUERIES, callback);

export const Http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function initAxios(router): void {
  Http.interceptors.request.use(authInterceptor as any);

  Http.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => errorInterceptor(error, router, Http),
  );
}
