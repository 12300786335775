import { h } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory, RouterView } from 'vue-router';

import signupService from '@/services/signup.service';
import useAuthStore from '@/store/auth';

import { getRoute, ROUTE_PARTS } from './constants';

const routes: RouteRecordRaw[] = [
  {
    path: getRoute(ROUTE_PARTS.LOGIN),
    name: 'Home',
    component: async () => import('@/views/Login.vue'),
    meta: { layout: 'AppLayoutAuth' },
    beforeEnter: (to, from, next) => {
      // Reject the navigation.
      const authStore = useAuthStore();
      if (authStore.getToken()) {
        if (from.path === getRoute(ROUTE_PARTS.LOGIN)) {
          next({ name: 'Jobs' });
        }
      } else {
        next();
      }
    },
  },
  {
    path: getRoute(ROUTE_PARTS.JOBS),
    // Vue router changed behavior of router-link-active class.
    // It's an offical workaround.
    // https://github.com/vuejs/rfcs/blob/master/active-rfcs/0028-router-active-link.md#unrelated-but-similiar-routes
    component: { render: () => h(RouterView) },
    meta: { layout: 'AppLayoutDefault' },
    children: [
      {
        path: getRoute(ROUTE_PARTS.JOBS),
        name: 'Jobs',
        component: async () => import('@/views/Jobs.vue'),
      },
      {
        path: getRoute(ROUTE_PARTS.JOBS, ':jobId', ':tab?'),
        name: 'JobItem',
        component: async () => import('@/views/JobItem.vue'),
        props: (route) => ({ ...route.params }),
      },
      {
        path: getRoute(ROUTE_PARTS.JOBS, ':jobId', ROUTE_PARTS.CHAMBERS, ':chamberId'),
        name: 'JobChamber',
        component: async () => import('@/views/JobItem.vue'),
        props: (route) => ({ ...route.params }),
      },
      {
        path: getRoute(ROUTE_PARTS.JOBS, ':jobId', ROUTE_PARTS.CHAMBERS, ':chamberId', ROUTE_PARTS.ROOMS, ':roomId'),
        name: 'JobChamberRoom',
        component: async () => import('@/views/JobChamberRoom.vue'),
        props: (route) => ({ ...route.params }),
      },
      {
        path: getRoute(ROUTE_PARTS.JOBS, ':jobId', ROUTE_PARTS.VISITS, ':visitId'),
        name: 'JobVisit',
        component: async () => import('@/views/Visit.vue'),
        props: (route) => ({ ...route.params }),
      },
    ],
  },
  {
    path: getRoute(ROUTE_PARTS.SETTINGS, ':tab?'),
    name: 'Settings',
    component: async () => import('@/views/Settings.vue'),
    props: (route) => ({ ...route.params }),
    meta: { layout: 'AppLayoutDefault' },
  },
  {
    path: getRoute(ROUTE_PARTS.EQUIPMENT),
    name: 'EquipmentList',
    component: async () => import('@/views/EquipmentList.vue'),
    meta: { layout: 'CustomLayoutWithOverflow' },
  },
  {
    path: getRoute(ROUTE_PARTS.EQUIPMENT, ':type', ':id'),
    name: 'Equipment',
    component: async () => import('@/views/Equipment.vue'),
    meta: { layout: 'AppLayoutDefault' },
  },
  {
    path: getRoute(ROUTE_PARTS.EQUIPMENT, ':type', ':id', ':name', ':beacon', 'log'),
    name: 'EquipmentLog',
    component: async () => import('@/views/EquipmentLog.vue'),
    meta: { layout: 'AppLayoutDefault' },
  },
  {
    path: getRoute(ROUTE_PARTS.ASSETS),
    name: 'Asset Manager',
    component: async () => import('@/views/Assets.vue'),
    meta: { layout: 'AppLayoutDefault' },
  },
  {
    path: getRoute(ROUTE_PARTS.VEHICLES),
    name: 'Vehicles',
    component: async () => import('@/views/Vehicles.vue'),
    meta: { layout: 'AppLayoutDefault' },
  },
  {
    path: getRoute(ROUTE_PARTS.MULTI_COMPANY_ASSETS),
    name: 'Enterprise',
    component: async () => import('@/views/MultiCompanyAssets.vue'),
    meta: { layout: 'CustomLayoutWithOverflow' },
  },
  {
    path: getRoute(ROUTE_PARTS.SIGNUP),
    name: 'SignUp',
    component: async () => import('@/views/SignUp.vue'),
    meta: { layout: 'SignUp' },
    beforeEnter: (_to, from, next) => {
      // Reject the navigation.
      const authStore = useAuthStore();
      if (authStore.getToken() && !authStore.getShouldConfirmLicense()) {
        if (from.path !== getRoute(ROUTE_PARTS.SIGNUP)) {
          next(false);
        } else {
          next();
        }
      } else {
        next();
      }
    },
  },
  {
    path: getRoute(ROUTE_PARTS.FORGOT_PASSWORD),
    name: 'Forgot',
    component: async () => import('@/views/ForgotPassword.vue'),
    meta: { layout: 'AppLayoutAuth' },
  },
  {
    path: getRoute(ROUTE_PARTS.RESET_PASSWORD),
    name: 'Reset',
    component: async () => import('@/views/ResetPassword.vue'),
    meta: { layout: 'AppLayoutAuth' },
  },
  {
    path: getRoute(ROUTE_PARTS.ACCOUNT),
    name: 'Account',
    component: async () => import('@/views/Account.vue'),
    meta: { layout: 'AppLayoutDefault' },
  },
  {
    path: getRoute(ROUTE_PARTS.SYNCED_EQUIPMENT, ':clientId'),
    name: 'SyncedEquipment',
    component: async () => import('@/views/SyncEquipment.vue'),
    meta: { layout: 'AppLayoutDefault' },
  },
  {
    path: getRoute(ROUTE_PARTS.CLAIMS),
    name: 'Claims',
    component: async () => import('@/views/Claims.vue'),
    meta: { layout: 'AppLayoutDefault' },
  },
  {
    path: getRoute(ROUTE_PARTS.RELOAD_PAGE),
    name: 'ReloadPage',
    component: async () => import('@/components/dialogs/ReloadPageDialog.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line consistent-return
router.beforeEach(async (to) => {
  const authStore = useAuthStore();
  const shouldVerify = authStore.getCredentials();
  if (to.name !== 'SignUp' && shouldVerify) {
    return { name: 'SignUp' };
  }

  // Make this request only if we don't have data in local storage.
  if (authStore.getShouldConfirmLicense() == null && authStore.getToken()) {
    const docs = await signupService.getLegalDocuments();
    authStore.setShouldConfirmLicense(Boolean(docs.length));
  }

  const shouldConfirmLicense = authStore.getShouldConfirmLicense() && authStore.getToken();
  if (to.name !== 'SignUp' && shouldConfirmLicense) {
    return { name: 'SignUp' };
  }

  return undefined;
});

router.onError(async (error, to, from) => {
  const { message } = error;
  if (/^Loading CSS chunk chunk-[^\s]+ failed/u.test(message) || /^Loading chunk chunk-[^\s]+ failed/u.test(message)) {
    await router.push({
      name: 'ReloadPage',
      query: {
        from: encodeURIComponent(from.path),
        to: encodeURIComponent(to.path),
      },
    });
  }
});

export default router;
