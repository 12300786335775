<template>
  <svg :class="svgClass" aria-hidden="true" :width="width || size" :height="height || size">
    <use :xlink:href="name"></use>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// https://webpack.js.org/guides/dependency-management/#context-module-api
const requireAll = (requireContext) => requireContext.keys().map(requireContext);
const req = require.context('../../assets/images/icons', false, /\.svg$/u);
requireAll(req);

export default defineComponent({
  name: 'SvgIcon',
  props: {
    iconName: {
      // Icon filename.
      type: String,
      required: true,
    },
    className: {
      // CSS class name.
      type: String,
      default: '',
    },
    height: [Number, String],
    width: [Number, String],
    size: {
      // For case when width=height.
      type: [Number, String],
      default: 10,
    },
  },
  computed: {
    name() {
      const icon = this.iconName;
      return icon ? `#icon-${icon}` : '';
    },
    svgClass() {
      const { className } = this;
      return className ? `svg-icon ${className}` : 'svg-icon';
    },
  },
});
</script>

<style>
.svg-icon {
  /* Filling with current color is important. */
  fill: currentColor;
  overflow: hidden;
}
</style>
