<template>
  <AppLayoutDefault :container-class="containerClass">
    <slot />
  </AppLayoutDefault>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, provide, ref } from 'vue';

import settingsService from '@/services/settings.service';
import useSettingTestAccountStore from '@/store/settings/test-account';

import AppLayoutDefault from './AppLayoutDefault.vue';

export default defineComponent({
  name: 'CustomLayoutWithOverflow',
  components: { AppLayoutDefault },
  setup() {
    const hasWidthOverflow = ref<boolean>(false);
    const testAccountStore = useSettingTestAccountStore();

    const containerClass = computed(() =>
      hasWidthOverflow.value ? 'custom-layout-for-equipments--width-overflow' : '',
    );

    const getTestAccountData = async () => {
      if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
        // Getting test account data only on staging.
        const isTestAccount = await settingsService.getIsTestAccount();
        testAccountStore.setTestAccount(isTestAccount);
      }
    };

    provide('setHasWidthOverflow', (value) => {
      hasWidthOverflow.value = value;
    });

    onMounted(async () => {
      await getTestAccountData();
    });

    return { containerClass };
  },
});
</script>

<style lang="scss" scoped>
:global(.container.custom-layout-for-equipments--width-overflow) {
  max-width: none;
}
</style>
