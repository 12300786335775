<template>
  <div class="logo">
    <img alt="Logo" src="@/assets/images/logo.svg" />
  </div>
  <div class="container-sm">
    <slot />
  </div>
  <TheFooter class="footer" />
</template>

<style lang="scss" scoped>
.container-sm {
  width: 100%;
  max-width: 640px;
  min-height: 320px;
  padding: 24px 56px 32px;
}
.logo {
  margin-top: 56px;
  margin-bottom: 40px;

  img {
    width: 138px;
  }
}
.footer {
  margin-top: 79px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import TheFooter from './TheFooter.vue';

export default defineComponent({
  name: 'AppLayoutAuth',
  components: {
    TheFooter,
  },
});
</script>
