import type { AxiosInstance } from 'axios';

import { serializeQueryParams } from '@/utils';

import type Subscription from '../models/subscription.type';
import { Http } from './axios.service';

export class SubscriptionService {
  constructor(private readonly httpClient: AxiosInstance) {}

  async getSubscriptionForAssets(): Promise<Subscription> {
    const { data } = await this.httpClient.get<Subscription>(
      `/v2/company/subscription/feature_status${serializeQueryParams({
        feature_name: 'Assets',
      })}`,
    );
    return data;
  }

  async getSubscriptionForJobs(): Promise<Subscription> {
    const { data } = await this.httpClient.get<Subscription>(
      `/v2/company/subscription/feature_status${serializeQueryParams({
        feature_name: 'DryLINK Jobs',
      })}`,
    );
    return data;
  }

  async getPricing(): Promise<Subscription[]> {
    const { data } = await this.httpClient.get<Subscription[]>('/v2/company/subscription/pricing');
    const updatedData = data.map((item) => {
      let newItem = item;
      if (typeof item.featureDescription === 'string') {
        newItem = {
          ...item,
          featureDescription: item.featureDescription.split('\\n'),
        };
      }
      newItem.freetrialDurationDays = newItem.tierPricing[0]?.freetrialDurationDays;
      return newItem;
    });
    return updatedData;
  }
}

export default new SubscriptionService(Http);
