import type { AxiosInstance, AxiosResponse } from 'axios';
import { groupBy, pick } from 'lodash';

import type { AssetLocationEquipment } from '@/models/asset/equipment.type';
import type { Company } from '@/models/company.type';
import type { Invitation } from '@/models/invitation.type';
import type { JobManagementAlerts } from '@/models/job-management-alerts.type';
import type { CompanyLabelType, EquipmentLabelType } from '@/models/label.type';
import type { Rule } from '@/models/rule.type';
import type { Settings } from '@/models/settings.type';
import type { InventoryLocationsData } from '@/models/settings/inventory-location-data.type';
import { serializeQueryParams } from '@/utils';
import { updateUnorderedList } from '@/utils/service';

import type { InventoryLocations } from '../models/settings/inventory-location.model';
import type { InventoryLocation } from '../models/settings/inventory-location.model';
import { InventoryLocationType, nextInventoryLocationID } from '../models/settings/inventory-location.model';
import { Http, mapQueriesInParallel } from './axios.service';

export class SettingsService {
  private readonly integrationsUrl = process.env.VUE_APP_BASE_INTEGRATIONS_API_URL;
  constructor(private readonly httpClient: AxiosInstance) {}

  async getAccountSettings(): Promise<Settings> {
    const { data } = await this.httpClient.get<Settings>('/v1/account');
    return data;
  }
  async deleteAccount(): Promise<number> {
    const { data } = await this.httpClient.delete<number>('/v1/account/self');
    return data;
  }

  async getIsTestAccount(): Promise<boolean> {
    const { data } = await this.httpClient.get<boolean>('/v1/account/test_account');
    return data;
  }

  async updateAccountSettings(account: Settings): Promise<Settings> {
    const { data } = await this.httpClient.put<Settings>('/v2/account', {
      ...account,
    });
    return data;
  }

  async updateCompanySettings(company: Company): Promise<Company> {
    const { data } = await this.httpClient.put<Company>('/v1/company', company);
    return data;
  }

  async getAutomationSettings(): Promise<{
    companyId: number;
    isEnabled: boolean;
  }> {
    const { data } = await this.httpClient.get<{
      companyId: number;
      isEnabled: boolean;
    }>('/v1/company/automation_status');
    return data;
  }

  async updateAutomationSettings(isEnabled: boolean): Promise<any> {
    const { data } = await this.httpClient.put(`/v1/company/automation_status${serializeQueryParams({ isEnabled })}`);
    return data;
  }

  async getCompanySettings(): Promise<Company> {
    const { data } = await this.httpClient.get<Company>('/v1/company');
    return data;
  }

  async getLogoCompanySettings(): Promise<string> {
    const { data } = await this.httpClient.get<string>('/v1/account/company_logo');
    return data;
  }

  async updateRule(rule: Rule): Promise<Rule> {
    const { data } = await this.httpClient.put<Rule>(
      `v1/alerts/jobmgmt/rule/${rule.settingId}?${serializeQueryParams(rule)}`,
    );
    return data;
  }

  async getTeamSettings(): Promise<Settings[]> {
    const { data } = await this.httpClient.get<Settings[]>('/v1/account/employee');
    return data;
  }

  async getPendingInvitations(): Promise<Invitation[]> {
    const { data } = await this.httpClient.get<Invitation[]>('/v1/invite');
    return data;
  }

  async changeImage(obj: any): Promise<Settings> {
    const config = {
      headers: {
        'Content-Type': undefined,
      },
    };
    const { data } = await this.httpClient.post('/v1/account/company_logo', obj, config);
    return data;
  }

  async deleteItemTeam(employeeId: string): Promise<AxiosResponse> {
    return this.httpClient.delete(`/v1/account/employee/${employeeId}`);
  }

  async getManagementSettings(): Promise<JobManagementAlerts> {
    const { data } = await this.httpClient.get<JobManagementAlerts>('/v1/alerts/jobmgmt');
    return this.configureAlertsText(data);
  }

  async updateTeamJobSettings(id: number, role: string): Promise<Settings> {
    const { data } = await this.httpClient.put<Settings>(`v1/account/employee/${id}?hasMgmtAlerts=${role}`);
    return data;
  }

  async updateTeamRoleSettings(id: number, role: string): Promise<Settings> {
    const { data } = await this.httpClient.put<Settings>(`v1/account/employee/${id}?role=${role}`);
    return data;
  }

  async newUsers(obj: any): Promise<Settings> {
    const { data } = await this.httpClient.post(`/v1/invite?email=${obj.email}&role=${obj.role}`);
    return data;
  }

  async updateOwnerSettings(employeeId: number): Promise<Settings> {
    const { data } = await this.httpClient.put<Settings>(`/v1/account/owner?employeeId=${employeeId}`);
    return data;
  }

  getDurationSetting(alertSettings: JobManagementAlerts) {
    return alertSettings.settings.find(
      ({ ruleName }) => ruleName.includes('More than') && ruleName.includes('Job Days'),
    );
  }

  private configureAlertsText(alertSettings: JobManagementAlerts) {
    const updatedSettings = alertSettings;
    for (const alert of updatedSettings.settings) {
      if (alert.ruleName === 'Job Created') {
        alert.realName = 'New Job Started';
        alert.ruleText = 'Send notifications when jobs are created.';
      } else if (alert.ruleName === 'Job Completed') {
        alert.realName = 'Job Completed';
        alert.ruleText = 'Send notifications when jobs are ended.';
      } else if (alert.ruleName.includes('More than') && alert.ruleName.includes('Job Days')) {
        alert.realName = 'Job Duration';
        alert.ruleText = 'Send notifications when jobs take longer than the number of days below.';
      } else {
        alert.realName = alert.ruleName;
      }
    }
    return updatedSettings;
  }

  async getInventoryLocations(): Promise<InventoryLocations> {
    const { data: locations } = await this.httpClient.get<InventoryLocations>('/v1/inventory/location');
    return locations.map((location) => ({
      ...location,
      id: nextInventoryLocationID(),
      lastReadDate: location.lastReadSecs != null ? new Date(location.lastReadSecs * 1000) : undefined,
    }));
  }

  async getInventoryLocationsData(): Promise<InventoryLocationsData> {
    const locations = await this.getInventoryLocations();
    return groupBy(locations, 'locationType') as InventoryLocationsData;
  }

  async updateInventoryLocations(initialData: InventoryLocationsData, data: InventoryLocationsData): Promise<any> {
    const {
      added: addedFixedLocations,
      updated: updatedFixedLocations,
      removed: removedFixedLocations,
    } = updateUnorderedList<InventoryLocation>(
      initialData[InventoryLocationType.FIXED] ?? [],
      data[InventoryLocationType.FIXED] ?? [],
      'id',
    );
    const {
      added: addedMobileLocations,
      updated: updatedMobileLocations,
      removed: removedMobileLocations,
    } = updateUnorderedList<InventoryLocation>(
      initialData[InventoryLocationType.MOBILE] ?? [],
      data[InventoryLocationType.MOBILE] ?? [],
      'id',
    );
    const {
      added: addedGatewayLocations,
      updated: updatedGatewayLocations,
      removed: removedGatewayLocations,
    } = updateUnorderedList<InventoryLocation>(
      initialData[InventoryLocationType.GATEWAY] ?? [],
      data[InventoryLocationType.GATEWAY] ?? [],
      'id',
    );

    const addPromises = [
      ...addedFixedLocations.map(async (location) =>
        this.httpClient.post(
          `/v1/inventory/location/fixed${serializeQueryParams(
            pick(location, ['name', 'address', 'city', 'state', 'zip']),
          )}`,
        ),
      ),
      ...addedMobileLocations.map(async (location) =>
        this.httpClient.post(
          `/v1/inventory/location/mobile${serializeQueryParams({
            phoneName: location.name,
          })}`,
        ),
      ),
      ...addedGatewayLocations.map(async (location) =>
        this.httpClient({
          url: `/v1/gateway/location${serializeQueryParams({
            fixedLocationId: location.fixedLocationId,
            uuid: location.gatewayUuid,
            type: location.gatewayType,
            name: location.name,
          })}`,
          baseURL: this.integrationsUrl,
          method: 'POST',
        }),
      ),
    ];

    const updatePromises = [
      ...updatedFixedLocations.map(async (location) =>
        this.httpClient
          .delete(`/v1/inventory/location/${location.invLocationId}`)
          .then(async () =>
            this.httpClient.post(
              `/v1/inventory/location/fixed${serializeQueryParams(
                pick(location, ['name', 'address', 'city', 'state', 'zip']),
              )}`,
            ),
          ),
      ),
      ...updatedMobileLocations.map(async (location) =>
        this.httpClient.put(
          `/v1/inventory/location/mobile/${location.invLocationId}${serializeQueryParams(pick(location, ['name']))}`,
        ),
      ),
      ...updatedGatewayLocations.map(async (location) =>
        this.httpClient({
          url: `/v1/gateway/location/${location.gatewayUuid}${serializeQueryParams({
            fixedLocationId: location.fixedLocationId,
            type: location.gatewayType,
            name: location.name,
          })}`,
          baseURL: this.integrationsUrl,
          method: 'PUT',
        }),
      ),
    ];

    const removePromises = [
      ...[...removedFixedLocations, ...removedMobileLocations].map(async (location) =>
        this.httpClient.delete(`/v1/inventory/location/${location.invLocationId}`),
      ),
      ...removedGatewayLocations.map(async (location) =>
        this.httpClient({
          url: `/v1/gateway/location/${location.gatewayUuid}`,
          baseURL: this.integrationsUrl,
          method: 'DELETE',
        }),
      ),
    ];

    return Promise.all([...addPromises, ...updatePromises, ...removePromises]);
  }

  async removeInventoryLocation(type: string, item: InventoryLocation): Promise<any> {
    if (type === 'Gateway') {
      if (item.gatewayUuid) {
        await this.httpClient({
          url: `/v1/gateway/location/${item.gatewayUuid}`,
          baseURL: this.integrationsUrl,
          method: 'DELETE',
        });
      }
    } else if (item.invLocationId) {
      await this.httpClient.delete(`/v1/inventory/location/${item.invLocationId}`);
    }
    return undefined;
  }

  async getCompanyLabels() {
    const { data: labels } = await this.httpClient.get<any[]>('/v1/clabel');
    return labels.map(
      (data) =>
        ({
          id: data.clabelId,
          name: data.label,
          equipmentCount: data.equipmentCount,
        }) as CompanyLabelType,
    );
  }

  async updateCompanyLabels(initialLabels: CompanyLabelType[], labels: CompanyLabelType[]) {
    const {
      added: addedLabelTypes,
      updated: updatedLabelTypes,
      removed: removedLabelTypes,
    } = updateUnorderedList<CompanyLabelType>(initialLabels, labels, 'id');

    const addPromises = [
      ...addedLabelTypes.map(async ({ name, equipmentCount }) =>
        this.httpClient.post(`/v1/clabel${serializeQueryParams({ label: name, equipmentCount })}`),
      ),
    ];

    const updatePromises = [
      ...updatedLabelTypes.map(async ({ id, name, equipmentCount }) =>
        this.httpClient.put(
          `/v1/clabel/${id}${serializeQueryParams({
            labelName: name,
            equipmentCount,
          })}`,
        ),
      ),
    ];

    const removePromises = [...removedLabelTypes.map(async ({ id }) => this.httpClient.delete(`/v1/clabel/${id}`))];

    await Promise.all([...addPromises, ...updatePromises, ...removePromises]);

    return undefined;
  }

  async removeCompanyLabel(label: CompanyLabelType) {
    if (label.id > 0) {
      await this.httpClient.delete(`/v1/clabel/${label.id}`);
    }
    return undefined;
  }

  async addEquipmentLabels(
    equipments: Partial<AssetLocationEquipment>[],
    labelIds: (number | string)[],
    labelValues: string[],
  ) {
    const datas: any[] = [];

    equipments.forEach(({ dehuID, cequipID, msensorID, equipmentLabels }) => {
      labelIds.forEach((clabelId, index) => {
        const labelValue = labelValues[index];
        if (equipmentLabels?.includes(labelValue)) {
          // Do not add existing label once again.
          return;
        }

        const data: any = { clabelId };
        if (dehuID !== -1) {
          data.dehuId = dehuID;
        } else if (cequipID !== -1) {
          data.cequipId = cequipID;
        } else if (msensorID !== -1) {
          data.msensorId = msensorID;
        } else {
          return;
        }

        datas.push(data);
      });
    });

    await mapQueriesInParallel(datas, async (data) => this.httpClient.post(`/v1/elabel${serializeQueryParams(data)}`));

    return undefined;
  }

  async removeEquipmentLabels(equipments: Partial<AssetLocationEquipment>[], labelIds: (number | string)[]) {
    const datas: any[] = [];

    equipments.forEach(({ dehuID, cequipID, msensorID }) => {
      const data: any = {};
      if (dehuID !== -1) {
        data.dehuId = dehuID;
      } else if (cequipID !== -1) {
        data.cequipId = cequipID;
      } else if (msensorID !== -1) {
        data.msensorId = msensorID;
      } else {
        return;
      }

      datas.push(data);
    });

    const equipmentLabelGroups = await mapQueriesInParallel(datas, async (data) => {
      const { data: result } = await this.httpClient.get<EquipmentLabelType[]>(
        `/v1/elabel/${serializeQueryParams(data)}`,
      );
      return result;
    });

    const elabelIds: number[] = [];

    equipmentLabelGroups.forEach((equipmentLabels) => {
      equipmentLabels.forEach(({ clabelId, elabelId }) => {
        if (labelIds.includes(clabelId)) {
          elabelIds.push(elabelId);
        }
      });
    });

    await mapQueriesInParallel(elabelIds, async (elabelId) => this.httpClient.delete(`/v1/elabel/${elabelId}`));

    return undefined;
  }
}

export default new SettingsService(Http);
