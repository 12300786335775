import type { AxiosInstance, AxiosResponse } from 'axios';

import type { LoginData } from '@/models/login-data.type';

import type { MenuData } from '../models/menu-data.type';
import { Http } from './axios.service';

// export type Options = { headers?: Record<string, any>; code?: number };

// Create type for custom error.
export type APIResponse = Error | LoginData;

export class AuthService {
  constructor(private readonly httpClient: AxiosInstance) {}

  async login({
    email,
    password,
    refreshToken = '',
  }: {
    email: string;
    password: string;
    refreshToken?: string;
  }): Promise<LoginData> {
    const params = new URLSearchParams();
    if (refreshToken) {
      params.append('grant_type', 'refresh_token');
      params.append('refreshtoken', refreshToken);
    } else {
      params.append('username', email);
      params.append('password', password);
      params.append('grant_type', 'password');
    }
    params.append('apikey', process.env.VUE_APP_API_KEY);
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { data } = await this.httpClient.post<LoginData>('/v2/oauth2/token', params, config);
    return data;
  }

  async getMenuData(): Promise<AxiosResponse<MenuData>> {
    return this.httpClient.get('/v1/account/company/stats');
  }

  // logout() {
  //   localStorage.removeItem('user');
  // }

  // register(user) {
  //   return axios.post(API_URL + 'signup', {
  //     username: user.username,
  //     email: user.email,
  //     password: user.password
  //   });
  // }
}

export default new AuthService(Http);
