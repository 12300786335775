import type { AxiosInstance } from 'axios';

import type { LoginData } from '@/models/login-data.type';

export const login = async (
  http: AxiosInstance,
  {
    email,
    password,
    refreshToken = '',
  }: {
    email?: string;
    password?: string;
    refreshToken?: string;
  },
): Promise<LoginData> => {
  const params = new URLSearchParams();
  if (refreshToken) {
    params.append('grant_type', 'refresh_token');
    params.append('refreshtoken', refreshToken);
  } else {
    params.append('username', email ?? '');
    params.append('password', password ?? '');
    params.append('grant_type', 'password');
  }
  params.append('apikey', process.env.VUE_APP_API_KEY);
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const { data } = await http.post<LoginData>('/v2/oauth2/token', params, config);
  return data;
};
