import type { AxiosInstance } from 'axios';

import type { Invitation } from '@/models/invitation.type';

import { Http } from './axios.service';

export class SignupService {
  constructor(private readonly httpClient: AxiosInstance) {}

  async resetPassword(email: string): Promise<{ message: string }> {
    const params = new URLSearchParams();
    params.append('email', email);
    params.append('apikey', process.env.VUE_APP_API_KEY);
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const { data } = await this.httpClient.post<{ message: string }>('/v1/password_reset', params, config);
    return data;
  }

  async getInvitationStatus(email: string): Promise<Invitation> {
    const config = {
      headers: {
        'X-Auth-Key': process.env.VUE_APP_API_KEY,
      },
    };
    const { data } = await this.httpClient.get<Invitation>(`/v1/invite/employee?email=${email}`, config);
    return data;
  }

  async checkEmailUnique(email: string): Promise<boolean> {
    const config = {
      headers: {
        'X-Auth-Key': process.env.VUE_APP_API_KEY,
      },
    };
    const { data } = await this.httpClient.get<boolean>(`/v1/account/check_email_unique?email=${email}`, config);
    return data;
  }

  async sendVerificationEmail(email: string): Promise<boolean> {
    const config = {
      headers: {
        'X-Auth-Key': process.env.VUE_APP_API_KEY,
      },
    };
    const { data } = await this.httpClient.post<boolean>(`/v1/account/email_verify?email=${email}`, undefined, config);
    return data;
  }

  async sendCompany(obj: any): Promise<any> {
    const config = {
      headers: {
        'X-Auth-Key': process.env.VUE_APP_API_KEY,
      },
    };
    const { data } = await this.httpClient.post('/v2/account/', obj, config);
    return data;
  }

  async getLegalDocuments() {
    const { data } = await this.httpClient.get('/v1/account/consent');
    return data;
  }

  async confirmLicense(legalId: number): Promise<number> {
    const { data } = await this.httpClient.put<number>(`/v1/account/consent/${legalId}`);
    return data;
  }

  async cancelSignup(accountId: number): Promise<number> {
    const config = {
      headers: {
        'X-Auth-Key': process.env.VUE_APP_API_KEY,
      },
    };
    const { data } = await this.httpClient.delete<number>(`/v1/account/${accountId}`, config);
    return data;
  }
}

export default new SignupService(Http);
