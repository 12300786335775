import { acceptHMRUpdate, defineStore } from 'pinia';

import type Subscription from '@/models/subscription.type';
import { SubscriptionStatus } from '@/models/subscription.type';

interface SubscriptionState {
  assets: Subscription | undefined;
  jobs: Subscription | undefined;
}

const useSubscriptionStore = defineStore('subscription', {
  state: (): SubscriptionState => ({
    assets: undefined,
    jobs: undefined,
  }),
  getters: {
    isAssetsEnabled(state: SubscriptionState) {
      const status = state.assets?.status;
      return status != null && status !== SubscriptionStatus.INACTIVE;
    },
    isJobsEnabled(state: SubscriptionState) {
      const status = state.jobs?.status;
      return status != null && status !== SubscriptionStatus.INACTIVE;
    },
    isJobsTrial(state: SubscriptionState) {
      const status = state.jobs?.status;
      return status != null && status === SubscriptionStatus.TRIAL;
    },
    jobsTrialMessage(state: SubscriptionState) {
      return state.jobs?.message ?? '';
    },
  },
  actions: {
    setAssets(assets: Subscription) {
      this.assets = assets;
    },
    setJobs(jobs: Subscription) {
      this.jobs = jobs;
    },
  },
});

export default useSubscriptionStore;

if (module.hot) {
  module.hot.accept(acceptHMRUpdate(useSubscriptionStore, module.hot));
}
