<template>
  <div class="dialog-header">
    <h6>{{ headerText }}</h6>
    <SvgIcon icon-name="close" class="close-btn" size="14" @click="onCloseIconClick" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SvgIcon from '@/components/base/SvgIcon.vue';

export default defineComponent({
  props: {
    headerText: String,
  },
  emits: ['closeClicked'],
  components: {
    SvgIcon,
  },
  setup(props, { emit }) {
    const onCloseIconClick = () => {
      emit('closeClicked', true);
    };
    return {
      onCloseIconClick,
    };
  },
});
</script>
