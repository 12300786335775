import GoolgePlace from '@/models/google-place.model';
import type Job from '@/models/job.model';

import { constructFullAddress } from './address';

export const formatOnlyNumbers = (str: number | string): string => str.toString().replace(/\D/gu, '');

export const formatPhoneNumber = (phoneNumber: number | string): string => {
  const phone = formatOnlyNumbers(phoneNumber);
  const formatted = phone.replace(/(\d{1,2})(\d{1})?(\d{1,2})?(\d{1,1})?(\d{1,4})?/u, (_, p1, p2, p3, p4, p5) => {
    let output = '';
    if (p1) output = `(${p1}`;
    if (p2) output += `${p2}) `;
    if (p3) output += `${p3}`;
    if (p4) output += `${p4} - `;
    if (p5) output += `${p5}`;
    return output;
  });
  return formatted;
};

export const compactExcludingNullAndUndefined = (data: any) =>
  Object.keys(data).reduce((result, key) => {
    const value = data[key];
    if (value != null) {
      return {
        ...result,
        [key]: value,
      };
    }
    return result;
  }, {});

export const serializeQueryParams = (obj: any): string =>
  `?${Object.keys(obj)
    .reduce<string[]>((a, k) => {
      if (obj[k] !== undefined) {
        const value = obj[k];
        a.push(`${k}=${encodeURIComponent(value)}`);
      }
      return a;
    }, [])
    .join('&')}`;

export const parseGoogleAutocomplete = (addressData): GoolgePlace => {
  const result = new GoolgePlace();
  for (const component of addressData) {
    const [componentType] = component.types;

    switch (componentType) {
    case 'street_number': {
      result.address1 = `${component.long_name} ${result.address1}`;
      break;
    }
    case 'route': {
      result.address1 += component.short_name;
      break;
    }
    case 'postal_code': {
      result.zip = `${component.long_name}${result.zip}`;
      break;
    }
    case 'postal_code_suffix': {
      result.zip = `${result.zip}-${component.long_name}`;
      break;
    }
    case 'locality':
      result.city = component.long_name;
      break;
    case 'administrative_area_level_1': {
      result.state = component.short_name;
      break;
    }
    case 'country':
      result.country = component.long_name;
      break;
    default:
      break;
    }
  }
  return result;
};

export const constructFullAddressForJob = (job: Job): string => {
  const { address, state, zip, city } = job;
  return constructFullAddress(address, state, zip, city);
};

export const compareVersions = (version1, version2) => {
  let nibble1;
  let nibble2;

  if (!version1 || !version2) {
    return NaN;
  }

  const separation1 = version1.indexOf('.');
  const separation2 = version2.indexOf('.');

  if (separation1 > -1) {
    nibble1 = version1.slice(0, separation1);
  } else {
    nibble1 = version1;
  }
  if (separation2 > -1) {
    nibble2 = version2.slice(0, separation2);
  } else {
    nibble2 = version2;
  }

  const int1 = parseInt(nibble1, 10);
  const int2 = parseInt(nibble2, 10);

  if (Number.isNaN(int1) || Number.isNaN(int2)) {
    return NaN;
  }
  if (int1 > int2) {
    return 1;
  }
  if (int1 < int2) {
    return -1;
  }
  if (separation1 < 0 || separation2 < 0) {
    return 0;
  }
  return compareVersions(version1.slice(separation1 + 1), version2.slice(separation2 + 1));
};

export const divideRecommendations = (value: number, base: number) => (base ? value / base : 0);

export const addColor = (data) => {
  const recommendations = data;
  const rearrangement = {};
  let i;
  let j;
  let totalFraction;

  if (recommendations.amRecommendations?.length) {
    for (i = 0; i < recommendations.amRecommendations.length; i += 1) {
      if (recommendations.amRecommendations[i]) {
        if (!rearrangement[recommendations.amRecommendations[i].chamberId]) {
          rearrangement[recommendations.amRecommendations[i].chamberId] = {
            totalFraction: 0,
            dehuList: [],
            roomList: {},
          };
        }
        recommendations.amRecommendations[i].inCompliance =
          recommendations.amRecommendations[i].actual >= recommendations.amRecommendations[i].minNumAirMovers &&
          recommendations.amRecommendations[i].actual <= recommendations.amRecommendations[i].maxNumAirMovers;
        rearrangement[recommendations.amRecommendations[i].chamberId].roomList[
          recommendations.amRecommendations[i].roomId
        ] = recommendations.amRecommendations[i];
      }
    }
  }
  if (recommendations.dehuRecommendations?.length) {
    for (i = 0; i < recommendations.dehuRecommendations.length; i += 1) {
      if (recommendations.dehuRecommendations[i]) {
        if (!rearrangement[recommendations.dehuRecommendations[i].chamberId]) {
          rearrangement[recommendations.dehuRecommendations[i].chamberId] = {
            totalFraction: 0,
            dehuList: [],
            roomList: {},
          };
        }
        totalFraction = 0;
        if (recommendations.dehuRecommendations[i].cubicFeetPerMinute) {
          totalFraction = divideRecommendations(
            recommendations.dehuRecommendations[i].actualCubicFeetPerMinute,
            recommendations.dehuRecommendations[i].cubicFeetPerMinute,
          );
        }
        if (recommendations.dehuRecommendations[i].pintsPerDay) {
          totalFraction += divideRecommendations(
            recommendations.dehuRecommendations[i].actualPintsPerDay,
            recommendations.dehuRecommendations[i].pintsPerDay,
          );
        } else if (!recommendations.dehuRecommendations[i].cubicFeetPerMinute) {
          // Assuming zero total value if we have no actual value.
          totalFraction = 0;
        }
        if (totalFraction < 0) {
          totalFraction = 0;
        }
        recommendations.dehuRecommendations[i].totalFraction = totalFraction;
        rearrangement[recommendations.dehuRecommendations[i].chamberId].dehuList.push(
          recommendations.dehuRecommendations[i],
        );
      }
    }

    Object.keys(rearrangement).forEach((key) => {
      if (rearrangement[key]) {
        rearrangement[key].inCompliance = true;
        rearrangement[key].hasSize = false;
        rearrangement[key].hasEquipment = false;
        if (rearrangement[key].dehuList?.length) {
          for (j = 0; j < rearrangement[key].dehuList.length; j += 1) {
            if (rearrangement[key].dehuList[j]) {
              rearrangement[key].totalFraction += rearrangement[key].dehuList[j].totalFraction;
              if (rearrangement[key].dehuList[j].cubicFeetPerMinute || rearrangement[key].dehuList[j].pintsPerDay) {
                rearrangement[key].hasSize = true;
              }
              if (
                rearrangement[key].dehuList[j].actualCubicFeetPerMinute ||
                rearrangement[key].dehuList[j].actualPintsPerDay
              ) {
                rearrangement[key].hasEquipment = true;
              }
              if (rearrangement[key].dehuList[j].cubicFeetPerMinute && !rearrangement[key].dehuList[j].pintsPerDay) {
                rearrangement[key].dehuList[j].displayCFM = true;
              } else {
                rearrangement[key].dehuList[j].displayCFM = false;
              }
            }
          }
          if (rearrangement[key].totalFraction < 0) {
            rearrangement[key].totalFraction = 0;
          }
          rearrangement[key].inCompliance = rearrangement[key].totalFraction >= 1.0;
          rearrangement[key].percentDone = rearrangement[key].totalFraction * 100;
          if (rearrangement[key].percentDone < 0) {
            rearrangement[key].percentDone = 0;
          } else if (rearrangement[key].percentDone > 100) {
            rearrangement[key].percentDone = 100;
          }
          rearrangement[key].isGreen = false;
          rearrangement[key].isYellow = false;
          rearrangement[key].isOrange = false;
          rearrangement[key].isRed = false;
          if (rearrangement[key].percentDone >= 89) {
            rearrangement[key].isGreen = true;
          } else if (rearrangement[key].percentDone >= 60) {
            rearrangement[key].isYellow = true;
          } else if (rearrangement[key].percentDone >= 30) {
            rearrangement[key].isOrange = true;
          } else {
            rearrangement[key].isRed = true;
          }
        }
        if (rearrangement[key].roomList) {
          Object.keys(rearrangement[key].roomList).forEach((keyJ) => {
            if (rearrangement[key].roomList[keyJ]) {
              rearrangement[key].inCompliance &&= rearrangement[key].roomList[keyJ].inCompliance;
              rearrangement[key].roomList[keyJ].hasSize = rearrangement[key].roomList[keyJ].maxNumAirMovers > 0;
              rearrangement[key].roomList[keyJ].hasEquipment = rearrangement[key].roomList[keyJ].actual > 0;
              totalFraction = divideRecommendations(
                rearrangement[key].roomList[keyJ].actual * 100,
                rearrangement[key].roomList[keyJ].minNumAirMovers,
              );
              if (totalFraction < 0) {
                totalFraction = 0;
              } else if (totalFraction > 100) {
                totalFraction = 100;
              }
              rearrangement[key].roomList[keyJ].isGreen = false;
              rearrangement[key].roomList[keyJ].isYellow = false;
              rearrangement[key].roomList[keyJ].isOrange = false;
              rearrangement[key].roomList[keyJ].isRed = false;
              if (totalFraction >= 100) {
                rearrangement[key].roomList[keyJ].isGreen = true;
              } else if (totalFraction >= 66) {
                rearrangement[key].roomList[keyJ].isYellow = true;
              } else if (totalFraction >= 33) {
                rearrangement[key].roomList[keyJ].isOrange = true;
              } else {
                rearrangement[key].roomList[keyJ].isRed = true;
              }
              rearrangement[key].roomList[keyJ].percentDone = totalFraction;
            }
          });
        }
      }
    });
  }
  return rearrangement;
};
