export const EMPTY_LOCATION = {
  address: '',
  city: '',
  state: '',
  zip: '',
};

export const parseLocation = (address?: string) => {
  if (address == null) {
    return EMPTY_LOCATION;
  }
  const data = /\s*([\w\s]*)\s*,\s*([\w\s]*)\s*,\s*([\w]*)\s*([\w]*)\s*/u.exec(address);
  if (data == null) {
    return EMPTY_LOCATION;
  }
  return {
    address: data[1],
    city: data[2],
    state: data[3],
    zip: data[4],
  };
};

export const constructFullAddress = (address?: string, state?: string, zip?: string, city?: string) => {
  const hasComma = address && (state ?? zip);
  return [`${address ?? ''}${hasComma ? ',' : ''}`, city ?? '', state ?? '', zip ?? ''].join(' ');
};
