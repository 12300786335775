import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!

  return (_openBlock(), _createBlock(_component_q_tooltip, {
    class: "help-tooltip base-tooltip",
    ref: "baseTooltip",
    anchor: "top start",
    self: "bottom start",
    offset: [0, 6],
    onBeforeShow: _ctx.beforeShow
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: "base-tooltip__pointer",
        style: _normalizeStyle({ left: _ctx.left })
      }, null, 4),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["onBeforeShow"]))
}